main {
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 77vh;
  flex: 1;
}

/* Add for mobile devices */
@media only screen and (max-width: 600px) {
  main {
    min-height: 89vh;
  }
}
