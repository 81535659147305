.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.input-field {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.login-button {
  width: 50%;
  margin-top: 30px;
  padding: 10px;
  background-color: rgb(8, 176, 35);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login-button:hover {
  color: rgb(70, 248, 62);
}

.login-error-message {
  margin-top: 30px;
  color: red;
}
