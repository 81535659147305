.about h1 {
  text-align: center;
  font-size: 3rem;
}

.about p {
  text-align: center;
  font-size: 1.2rem;
  padding-left: 10%;
  padding-right: 10%;
}

@media (max-width: 768px) {
  .about h1 {
    font-size: 2.5rem;
  }

  .about p {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .about h1 {
    font-size: 2rem;
  }

  .about p {
    font-size: 1rem;
  }
}
