.userprofile {
  height: 110vh;
}

.userprofile h1 {
  text-align: center;
  font-size: 3rem;
  margin-bottom: -20px;
}

.userprofile p {
  text-align: center;
  font-size: 1.2rem;
  padding-left: 10%;
  padding-right: 10%;
}

@media (max-width: 768px) {
  .userprofile {
    height: 120vh;
  }

  .userprofile h1 {
    font-size: 2.5rem;
  }
}

@media (max-width: 576px) {
  .userprofile {
    height: 120vh;
  }

  .userprofile h1 {
    font-size: 2rem;
  }
}
